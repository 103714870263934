import { React, PropTypes } from '../../dependencies'
import Img from 'gatsby-image'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

const Gallery = ({ styles, photos, thumbnails }) => {
    const [isOpen, setOpen] = React.useState(false)
    const [photoIndex, setPhotoIndex] = React.useState(0)

    return (
        <div className={styles.container}>
            <Carousel
                arrows
                slidesPerScroll={1}
                slidesPerPage={1}
                infinite
                // autoPlay={5000}
                stopAutoPlayOnHover
                centered
            >
                {photos.map(image => (
                    <div key={image.id} className={styles.item}>
                        <Img
                            key={image.id}
                            sizes={image.sizes}
                            alt={image.title}
                            className={styles.image}
                            imgStyle={{ objectFit: 'contain' }}
                        />
                        <p className={styles.title}>{image.title}</p>
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

Gallery.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
}

export default Gallery
