export const container = () => ({
    flex: 1,
})

export const article = () => ({
    maxWidth: 600,
})

export const title = () => ({
    fontSize: '1.5rem',
    fontWeight: 400,
    marginBottom: '1.25em',
})

export const text = () => ({
    '& li:not(:last-child)': {
        marginBottom: '.5em',
    },

    '& h1': {
        marginTop: '1.5em',
        fontSize: '1.5rem',
        fontWeight: 400,
        marginBottom: '1.5em',
    },

    '& h2': {
        fontSize: '1.15rem',
        fontWeight: 400,
        marginTop: '1.5em',
        marginBottom: '.5em',
    },

    '& h3': {
        fontSize: '1.05rem',
        fontWeight: 400,
        marginTop: '1em',
        marginBottom: '.5em',
    },

    '& p': {
        marginBottom: '1em',
    },
})
