import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { graphql } from 'gatsby'
import Article from '../components/Article'
import Gallery from '../components/Gallery'

const IndexPage = ({ data }) => (
    <Layout center>
        <SEO title="Tvůrčí tým architektů" />
        <Gallery photos={data.contentfulGallery.photos} />
    </Layout>
)

export const query = graphql`
    query HomePageQuery {
        contentfulGallery {
            photos {
                id
                title
                sizes(maxHeight: 800) {
                    ...GatsbyContentfulSizes
                }
            }
        }
    }
`

export default IndexPage
