export const container = ({ theme }) => ({
    display: 'inline-block',
    width: '100%',
    maxWidth: `calc(${theme.metrics.containerWidth} - ${theme.metrics.mainMenuWidth}px - 4rem)`,

    '@media(min-width: 45rem)': {
        width: `calc(100vw - ${theme.metrics.mainMenuWidth}px - 4rem)`,
    },

    '& .BrainhubCarousel__arrows': {
        backgroundColor: 'transparent',
        padding: 18,

        ':hover': {
            backgroundColor: 'transparent',
        },
        ':hover:enabled span': {
            borderColor: theme.colors.link,
        },
    },
    '& .BrainhubCarousel__arrows span': {
        borderColor: theme.colors.link,
    },
})

export const item = () => ({
    width: '100%',
    display: 'flex',
    height: 350,
    flexDirection: 'column',
    alignItems: 'center',

    '@media(min-width: 45rem)': {
        height: '80vh',
        minHeight: 400,
    },
})

export const title = () => ({
    fontSize: '1.1rem',
    marginTop: '1.5rem',
})

export const image = () => ({
    height: '100%',
    width: '100%',
    marginRight: '3rem',
    marginLeft: '3rem',
})
