import { React, PropTypes } from '../../dependencies'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Article = ({ styles, title, text }) => (
    <article className={styles.container}>
        <div className={styles.article}>
            {title && <h1 className={styles.title}>{title}</h1>}
            <div className={styles.text}>{documentToReactComponents(text.json)}</div>
        </div>
    </article>
)

Article.propTypes = {
    styles: PropTypes.shape({}).isRequired,
}

export default Article
